var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeader', {
    attrs: {
      "title": _vm.portfolio.name,
      "sub-title": _vm.totalFloorArea,
      "no-border": true
    }
  }), _c('TabView', {
    attrs: {
      "route-name": "portfolio-view",
      "tabs": _vm.tabs,
      "loading": _vm.loadingAction.get
    }
  }, [_c('router-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }