var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "PageHeader",
    class: {
      'PageHeader-border': !_vm.noBorder
    }
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col d-flex align-items-center"
  }, [_vm.icon ? _c('div', {
    staticClass: "mr-4"
  }, [_c('i', {
    class: `fa fa-2x fa-fw fa-${_vm.icon}`
  })]) : _vm._e(), _c('div', {
    staticClass: "flex-grow-1"
  }, [_c('h1', {
    class: {
      'mb-1': _vm.subTitle,
      'mb-0': !_vm.subTitle
    }
  }, [_vm._v(_vm._s(_vm.title))]), _vm.subTitle ? _c('p', {
    staticClass: "text-muted font-w500 mb-0"
  }, [_vm._v(_vm._s(_vm.subTitle))]) : _vm._e()])]), _vm.rightTitle ? _c('div', {
    staticClass: "col text-right"
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.rightTitle))])]) : _vm._e(), _vm.$slots['actions'] ? _c('div', {
    staticClass: "col text-right"
  }, [_vm._t("actions")], 2) : _vm._e(), _vm.spinner ? _c('div', {
    staticClass: "col text-right"
  }, [_c('i', {
    staticClass: "fa fa-spinner-third fa-spin fa-3x text-info"
  })]) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }