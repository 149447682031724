var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "content pb-0 pt-2"
  }, [_c('ul', {
    staticClass: "nav nav-tabs nav-tabs-alt justify-content-center justify-content-md-start"
  }, _vm._l(_vm.visibleTabs, function (tab) {
    var _tab$altRoutes;
    return _c('li', {
      key: tab.name,
      staticClass: "nav-item"
    }, [_c('router-link', {
      staticClass: "nav-link",
      class: {
        disabled: tab.isComingSoon,
        active: (_tab$altRoutes = tab.altRoutes) === null || _tab$altRoutes === void 0 ? void 0 : _tab$altRoutes.includes(_vm.$route.name)
      },
      attrs: {
        "data-cy": `tab-${tab.route}`,
        "disabled": _vm.loading,
        "to": {
          name: tab.route
        },
        "active-class": "active",
        "exact": tab.exact !== undefined ? tab.exact : _vm.exact
      }
    }, [typeof tab.icon === 'string' || tab.icon instanceof String ? _c('i', {
      staticClass: "fa-duotone fa-fw text-muted mr-1",
      class: tab.icon
    }) : _c(tab.icon, {
      tag: "component",
      staticClass: "mr-1"
    }), _c('span', {
      staticClass: "d-none d-md-inline text-capitalize ml-1"
    }, [_vm._v(_vm._s(tab.name))])], 1)], 1);
  }), 0)]), _c('div', {
    staticClass: "content"
  }, [_vm.loading ? _c('Spinner', {
    staticClass: "py-5"
  }) : _c('div', {
    attrs: {
      "data-cy": `tab-content-${this.$route.name}`
    }
  }, [_vm._t("default")], 2)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }